import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import companyImage from "../assets/123.jpg"; // Adjust the path to your company image

// Import your logo images for mottos here
import trustworthinessLogo from "../assets/trust.jpg";
import dedicationLogo from "../assets/dedication.jpg";
import serviceQualityLogo from "../assets/quality.jpg";
import integrityLogo from "../assets/integrity.jpg";
import ceo from "../assets/ceopapa.JPG";

const About = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Define references for the images
  const mottosRef = useRef([]);
  const ceoRef = useRef(null);

  const mottos = [
    { logo: trustworthinessLogo, motto: "Trustworthiness", animation: "animate-slideInLeft" },
    { logo: dedicationLogo, motto: "Dedication", animation: "animate-slideInRight" },
    { logo: serviceQualityLogo, motto: "Premium Service Quality", animation: "animate-slideInLeft" },
    { logo: integrityLogo, motto: "Integrity", animation: "animate-slideInRight" },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing after it becomes visible
          }
        });
      },
      {
        threshold: 0.7, // Adjust the threshold to control when the animation starts
      }
    );

    const ceoCurrentRef = ceoRef.current;
    const mottosCurrentRef = mottosRef.current;

    if (ceoCurrentRef) {
      observer.observe(ceoCurrentRef);
    }
    mottosCurrentRef.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      if (ceoCurrentRef) {
        observer.unobserve(ceoCurrentRef);
      }
      mottosCurrentRef.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className="min-h-screen">
      <Navbar />
      {/* Hero section with background image */}
      <div className="relative bg-cover bg-center flex items-center justify-center shadow-lg"
        style={{
          backgroundImage: `url(${companyImage})`,
          height: 'calc(100vh - 4rem)', // Adjust the height to fit the screen minus the navbar height
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-center">
          <h1 className="text-6xl md:text-7xl font-bold text-white">ABOUT US</h1>
        </div>
      </div>

      {/* Main content section */}
      <div className="bg-gray-100 py-8 px-4">
        <div className="container mx-auto">
          {/* Welcome Message */}
          <section className="mb-8">
            <h2 className="text-3xl md:text-6xl font-bold text-center mb-4 text-custom-blue">Welcome to LOGISTICA</h2>
            <p className="text-base md:text-lg mb-8">
            Welcome to LOGISTICA, a premier name in freight forwarding and logistics based in Pakistan. We specialize in delivering reliable and cost-effective solutions for all your logistics and freight needs.

At LOGISTICA, we offer a wide range of services, including import,export freight (LCL/FCL & AIR), warehousing, transportation, and distribution. Our clients view us as a crucial partner in their logistics operations, seamlessly integrating into their supply chain to enhance efficiency and reliability.

We pride ourselves on leveraging cutting-edge technology and innovative practices to stay ahead in the industry. Our commitment to excellence and collaboration ensures that we consistently exceed our clients' expectations, fostering long-term partnerships and driving growth.

Join the growing list of satisfied clients who trust LOGISTICA for their comprehensive freight and logistics solutions.

</p>
          </section>

         {/* General Information Section */}
<section className="mb-8">
<h2 className="text-3xl md:text-6xl font-bold text-center mb-4 text-custom-blue">OUR SERVICES</h2>
  <p className="text-base md:text-lg">
    At LOGISTICA, we offer a full suite of freight forwarding and logistics services tailored to meet the diverse needs of our global clientele.
  </p>
  <p className="text-base md:text-lg">
    Our clients rely on us as a key partner for their import,export needs, including LCL, FCL, and air freight, as well as warehousing, transportation, and distribution. We are dedicated to integrating seamlessly into their operations to ensure smooth and efficient logistics management.
  </p>
  <p className="text-base md:text-lg">
    By employing the latest technology and innovative logistics practices, LOGISTICA consistently enhances operational efficiency. Our commitment to cutting-edge solutions and collaborative approaches has made us a trusted choice for companies seeking reliable and effective logistics services.
  </p>
</section>


          {/* CEO Message Section */}
          <section className="mb-8 text-center">
            <h2 className="text-3xl md:text-6xl font-bold mb-4 text-center text-custom-blue">CEO's Message</h2>
            <div className="flex flex-col items-center">
              <div className="w-full max-w-xs md:max-w-[15rem] mb-4">
                <img
                  ref={ceoRef}
                  src={ceo}
                  alt="CEO"
                  className={`w-full h-auto object-contain rounded border-2 border-black ${isVisible ? 'animate-slideInLeft' : 'opacity-0'}`}
                />
              </div>
              <div className="max-w-2xl px-4">
                <p className="text-base md:text-lg">
                Our CEO, Ishtiaq Chaudhary, is committed to advancing our infrastructure and elevating our services. Under his leadership, LOGISTICA consistently strives for excellence and innovation, ensuring that our clients receive the most reliable and efficient solutions in the industry. Trust in our dedication to delivering top-tier service and seamless logistics.With over 25 years of experience in NVOCC import and clearing,His constant hard work and resilient nature have been instrumental in our success. His expertise and leadership continue to drive LOGISTICA forward, making us a trusted partner in the logistics industry.</p>  </div>
            </div>
          </section>

          {/* Company Mottos Section */}
          <section className="mb-8">
            <h2 className="text-3xl md:text-6xl text-custom-blue font-bold mb-4 text-center">Company Mottos</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 px-4">
              {mottos.map((motto, index) => (
                <div
                  key={index}
                  ref={(el) => (mottosRef.current[index] = el)}
                  className={`flex flex-col items-center ${isVisible ? motto.animation : 'opacity-0'}`}
                >
                  <div className="w-32 h-32 flex items-center justify-center overflow-hidden bg-white">
                    <img src={motto.logo} alt={motto.motto} className="w-full h-full object-cover" />
                  </div>
                  <p className="text-base md:text-lg text-center mt-2">{motto.motto}</p>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
