import React, { useState, useEffect, useRef } from 'react';
import seafreightImage from '../assets/air121.jpg'; // Adjust the path as necessary
import air2 from '../assets/air122.jpg'; // Adjust the path as necessary
import Navbar from './Navbar';
import Footer from './Footer';
import Airvid from '../assets/airvid.mp4'; // Adjust the path as necessary

const AirFreight = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const imageRefs = useRef([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slideInRight', 'opacity-100');
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRefs = imageRefs.current;
    currentImageRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentImageRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <div className="h-screen relative">
          <Navbar />
          <div className="absolute inset-0 w-full h-full bg-black opacity-65">
            <img src={seafreightImage} alt="Sea Freight service" className="absolute inset-0 w-full h-full object-cover" />
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-5xl font-bold mb-4 text-black">AIR FREIGHT</h1>
            <p className="text-3xl font-semibold text-black">Efficient, Reliable, and Secure</p>
          </div>
        </div>
      ) : (
        <div className="h-screen relative">
          <Navbar />
          <div
            className="absolute inset-0 w-full h-full bg-black opacity-65"
            style={{
              maxHeight: '100vh',
              overflow: 'hidden',
            }}
          >
            <video
              autoPlay
              loop
              muted
              className="absolute inset-0 w-full h-full object-cover"
              style={{
                maxHeight: '100vh',
                width: '100vw',
              }}
            >
              <source src={Airvid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-8xl font-bold mb-4 text-black">AIR FREIGHT</h1>
            <p className="text-5xl font-semibold text-black">Efficient, Reliable, and Secure</p>
          </div>
        </div>
      )}
      <div className="font-sans">
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h2 className="text-3xl font-semibold text-custom-blue">Optimize Your Global Logistics with Efficient Air Freight Solutions</h2>
            <p className="mt-4 text-lg leading-relaxed">
              Are you seeking a dependable and expedient method for transporting your goods internationally? Our air freight services offer a swift and secure solution, guaranteeing the timely arrival of your shipments. Our team of seasoned experts is dedicated to guiding you through the intricacies of air freight, providing personalized logistics solutions and competitive pricing tailored to your unique requirements.
            </p>
            <h3 className="text-2xl font-semibold text-custom-blue mt-6">Enhance Your Logistics Experience with Our Expertise</h3>
            <ul className="list-disc mt-4 text-lg leading-relaxed">
              <li><b>Streamlined Shipping:</b>  Our air freight services ensure rapid transportation of your goods, minimizing transit times and maximizing efficiency.</li>
              <li><b>Competitive Pricing:</b>  We offer customized quotes and flexible pricing options to accommodate your budget and shipping needs.</li>
              <li><b>Seamless Logistics:</b>  Our experienced team handles all aspects of air freight, from documentation to delivery, ensuring a smooth and hassle-free experience.</li>
            </ul>
          </div>
          <div className="lg:w-1/2">
            <img
              ref={(el) => (imageRefs.current[0] = el)}
              src={air2}
              alt="Cargo being loaded onto a plane"
              className="rounded-lg shadow-lg opacity-0"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h3 className="text-2xl font-semibold text-custom-blue">Improve Your Logistics Operations with Our Air Freight Solutions</h3>
            <p className="mt-4 text-lg leading-relaxed">
              At <b>LOGISTICA</b>, we understand the importance of efficient logistics in today's fast-paced global market. Our air freight services are designed to provide a reliable, cost-effective, and personalized solution for your international shipping needs. Contact us today to learn more about our air freight services and how we can help optimize your logistics operations.
            </p>
          </div>
          <div className="lg:w-1/2">
            <img
              ref={(el) => (imageRefs.current[1] = el)}
              src={seafreightImage}
              alt="Air cargo ready for dispatch"
              className="rounded-lg shadow-lg opacity-0"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AirFreight;
