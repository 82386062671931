import React, { useState, useEffect, useRef } from 'react';
import Seavid from '../assets/roadfrieght111.mp4'; // Adjust the path as necessary
import landfreightImage from '../assets/road122.jpg'; // Adjust the path as necessary
import land2 from '../assets/road121.jpg'
import Navbar from './Navbar';
import Footer from './Footer';

const LandFreight = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const imageRefs = useRef([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slideInRight', 'opacity-100');
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRefs = imageRefs.current;
    currentImageRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentImageRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <div className="h-screen relative">
          <Navbar />
          <div className="absolute inset-0 w-full h-full bg-black opacity-65">
            <img src={landfreightImage} alt="Land Freight service" className="absolute inset-0 w-full h-full object-cover" />
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-5xl font-bold mb-4 text-black">ROAD FREIGHT</h1>
            <p className="text-3xl font-semibold text-black">Cost-Efficient and Reliable</p>
          </div>
        </div>
      ) : (
        <div className="h-screen relative">
          <Navbar />
          <div
            className="absolute inset-0 w-full h-full bg-black opacity-65"
            style={{
              maxHeight: '100vh',
              overflow: 'hidden',
            }}
          >
            <video
              autoPlay
              loop
              muted
              className="absolute inset-0 w-full h-full object-cover"
              style={{
                maxHeight: '100vh',
                width: '100vw',
              }}
            >
              <source src={Seavid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-8xl font-bold mb-4 text-black">ROAD FREIGHT</h1>
            <p className="text-5xl font-semibold text-black">Cost-Efficient and Reliable</p>
          </div>
        </div>
      )}
      <div className="font-sans">
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h2 className="text-3xl font-semibold text-custom-blue">Optimize Your Domestic Logistics with Efficient Land Freight Solutions</h2>
            <p className="mt-4 text-lg leading-relaxed">
              Are you looking for a cost-effective method for transporting your goods domestically? Our land freight services offer a reliable and secure solution, guaranteeing the timely arrival of your shipments. Our team of seasoned experts is dedicated to guiding you through the intricacies of land freight, providing personalized logistics solutions and competitive pricing tailored to your unique requirements.
            </p>
            <h3 className="text-2xl font-semibold text-custom-blue mt-6">Enhance Your Logistics Experience with Our Expertise</h3>
            <ul className="list-disc mt-4 text-lg leading-relaxed">
              <li><b>Door-to-Door Delivery:</b> We handle everything from pickup to delivery, ensuring a hassle-free experience.</li>
              <li><b>Real-Time Tracking:</b> Stay updated on your shipment's status with our advanced tracking system.</li>
              <li><b>Customized Solutions:</b> Our experts will work with you to create a tailored land freight solution that meets your unique needs.</li>
            </ul>
          </div>
          <div className="lg:w-1/2">
            <img
              ref={(el) => (imageRefs.current[0] = el)}
              src={landfreightImage}
              alt="Truck transporting goods"
              className="rounded-lg shadow-lg opacity-0"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h3 className="text-2xl font-semibold text-custom-blue">Improve Your Logistics Operations with Our Land Freight Solutions</h3>
            <p className="mt-4 text-lg leading-relaxed">
              At <b>LOGISTICA</b>, we understand the importance of efficient logistics in today's fast-paced market. Our land freight services are designed to provide a reliable, cost-effective, and personalized solution for your domestic shipping needs. Contact us today to learn more about our land freight services and how we can help optimize your logistics operations.
            </p>
          </div>
          <div className="lg:w-1/2">
            <img
              ref={(el) => (imageRefs.current[1] = el)}
              src={land2}
              alt="Delivery truck on the road"
              className="rounded-lg shadow-lg opacity-0"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandFreight;
