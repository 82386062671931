import Home from '../src/components/Home.jsx';
import SeaFreight from '../src/components/seafreights.jsx'
import AirFreight from './components/airfrieght.jsx';
import LandFreight from './components/landfrieght.jsx';
import Warehouse from './components/warehouse.jsx';
import About from './components/About.jsx';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/seafreight" element={<SeaFreight />} />
        <Route path="/airfreight" element={<AirFreight />} />
        <Route path="/landfreight" element={<LandFreight />} />
        <Route path="/warehouse" element={<Warehouse />} />
        <Route path="/about" element={<About />} />
        
      </Routes>
    </Router>
  );
}

export default App;
