import React from "react";
import { PiffaLogo, KcciLogo, JctransLogo, GLALogo, FiataLogo } from "../assets/index.js"; // Example paths to logos

const Affiliation = () => {
  return (
    <div id="affiliation" className="bg-white text-custom-blue py-12">
      <div className="container mx-auto text-center px-4">
        <h1 className="text-3xl font-bold mb-10">OUR AFFILIATIONS</h1>
        <div className="overflow-hidden relative">
          <div className="flex animate-slideContinuous space-x-8">
            <div className="flex space-x-8 w-full">
              <img src={KcciLogo} alt="KCCI LOGO" className="h-32" />
              <img src={PiffaLogo} alt="PIFFA LOGO" className="h-32" />
              <img src={JctransLogo} alt="JCTRANS LOGO" className="h-16 mt-5" />
              <img src={GLALogo} alt="GLA LOGO" className="h-32" />
              <img src={FiataLogo} alt="FIATA LOGO" className="h-32" />
              {/* Duplicate logos to ensure continuous sliding effect */}
              <img src={KcciLogo} alt="KCCI LOGO" className="h-32" />
              <img src={PiffaLogo} alt="PIFFA LOGO" className="h-32" />
              <img src={JctransLogo} alt="JCTRANS LOGO" className="h-16 mt-5" />
              <img src={GLALogo} alt="GLA LOGO" className="h-32" />
              <img src={FiataLogo} alt="FIATA LOGO" className="h-32" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
