import React, { useState, useRef, useEffect } from 'react';
import LOGO from '../assets/lopo.png';
import EmailIcon from '../assets/email.jpg';
import PhoneIcon from '../assets/phone.jpg';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Contact from '../components/ContactUs.jsx';

const Navbar = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileServicesOpen, setIsMobileServicesOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  const hideTimeout = useRef(null);
  const enterTimeout = useRef(null);

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@logistica.com.pk';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+9235308885';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleMobileServices = () => {
    setIsMobileServicesOpen(!isMobileServicesOpen);
  };

  const handleContactClick = () => {
    setIsContactOpen(true);
  };

  const handleCloseContact = () => {
    setIsContactOpen(false);
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
    if (enterTimeout.current) {
      clearTimeout(enterTimeout.current);
    }
  };

  const handleMouseLeave = () => {
    enterTimeout.current = setTimeout(() => {
      hideTimeout.current = setTimeout(() => {
        setShowDropdown(false);
      }, 200); // Close after 2 seconds
    }, 200); // Delay before starting to count the 2 seconds
  };

  useEffect(() => {
    return () => {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
      if (enterTimeout.current) {
        clearTimeout(enterTimeout.current);
      }
    };
  }, []);

  return (
    <>
      <nav className="bg-white shadow-md p-6 relative z-50">
        <div className="container mx-auto flex justify-between items-center relative z-50">
          {/* Logo */}
          <div className="flex items-center">
            {/* Logo Image */}
            <img
              src={LOGO}
              alt="Logo"
              className="h-auto w-40 sm:w-48 md:w-56 lg:w-64 xl:w-72 mr-4"

            />
          </div>

          {/* Mobile Menu Button - Right side */}
          <div className="block sm:hidden ml-auto mr-4">
            <button
              onClick={toggleMobileMenu}
              className="text-black hover:text-gray-700 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMobileMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* Navigation Links - Desktop */}
          <div className="hidden sm:flex sm:flex-1 sm:justify-center">
            <div className="space-x-6 flex items-center">
              <Link
                to="/"
                className="text-black font-bold text-lg hover:text-blue-500"
              >
                Home
              </Link>
              <div
                className="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={dropdownRef}
              >
                <span
                  className="text-black font-bold text-lg hover:text-blue-500 cursor-pointer"
                >
                  Services
                </span>
                <div
                  className={`absolute top-full left-0 mt-1 w-48 bg-white shadow-lg rounded-md ${
                    showDropdown ? 'block' : 'hidden'
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="/airfreight"
                    className="block px-4 py-2 text-black hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out"
                  >
                    Air Freight
                  </Link>
                  <Link
                    to="/seafreight"
                    className="block px-4 py-2 text-black hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out"
                  >
                    Sea Freight
                  </Link>
                  <Link
                    to="/landfreight"
                    className="block px-4 py-2 text-black hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out"
                  >
                    Road Freight
                  </Link>
                  <Link
                    to="/warehouse"
                    className="block px-4 py-2 text-black hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out"
                  >
                    Warehouse
                  </Link>
                </div>
              </div>
              <Link
                to="/about"
                className="text-black font-bold text-lg hover:text-blue-500"
              >
                About Us
              </Link>
              <button
                className="text-black font-bold text-lg border-none px-4 py-2 rounded hover:text-blue-500 transition duration-300"
                onClick={handleContactClick}
              >
                Contact
              </button>
            </div>
          </div>

          {/* Mobile Menu - Dropdown */}
          {isMobileMenuOpen && (
            <div className="sm:hidden absolute top-0 right-0 mt-16 w-full bg-white shadow-lg z-50">
              <div className="px-2 py-3 space-y-1">
                <Link
                  to="/"
                  className="block text-black font-bold text-lg hover:text-blue-500 py-2"
                >
                  Home
                </Link>
                <div className="relative">
                  <button
                    className="block text-black font-bold text-lg w-full text-left py-2 hover:text-blue-500"
                    onClick={toggleMobileServices}
                  >
                    Services
                  </button>
                  {isMobileServicesOpen && (
                    <div className="pl-4">
                      <Link
                        to="/airfreight"
                        className="block text-black font-bold text-lg hover:text-blue-500 py-2"
                      >
                        Air Freight
                      </Link>
                      <Link
                        to="/seafreight"
                        className="block text-black font-bold text-lg hover:text-blue-500 py-2"
                      >
                        Sea Freight
                      </Link>
                      <Link
                        to="/landfreight"
                        className="block text-black font-bold text-lg hover:text-blue-500 py-2"
                      >
                        Road Freight
                      </Link>
                      <Link
                        to="/warehouse"
                        className="block text-black font-bold text-lg hover:text-blue-500 py-2"
                      >
                        Warehouse
                      </Link>
                    </div>
                  )}
                </div>
                <Link
                  to="/about"
                  className="block text-black font-bold text-lg hover:text-blue-500 py-2"
                >
                  About Us
                </Link>
                <button
                  className="block text-black font-bold text-lg w-full text-left py-2 hover:text-blue-500"
                  onClick={handleContactClick}
                >
                  Contact Us
                </button>
              </div>
            </div>
          )}

          {/* Contact Information */}
          <div className="text-black text-lg flex items-center space-x-4">
            {/* Email */}
            <div
              className="flex items-center"
              onClick={handleEmailClick}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={EmailIcon}
                alt="Email Icon"
                className="h-11 w-auto mr-2 cursor-pointer"
              />
            </div>

            {/* Phone */}
            <div
              className="flex items-center"
              onClick={handlePhoneClick}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={PhoneIcon}
                alt="Phone Icon"
                className="h-8 w-auto mr-2 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {isContactOpen && <Contact onClose={handleCloseContact} />}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
