import React from 'react';
import { Air, Sea, Road } from '../assets/index.js'; // Import your images here
import hello from '../assets/ware122.jpg'; // Adjust the path as necessary    

const GridSection = () => {
    return (
        <>
            <div className="text-center mt-12 mb-8">
                <h1 className="text-4xl md:text-6xl font-bold text-custom-blue">Our Services</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 h-auto md:h-screen overflow-hidden">
                {/* First Grid Item */}
                <div className="relative group hover:scale-110 transition-transform duration-300 ease-in-out overflow-hidden h-96 md:h-auto">
                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                        <div className="text-white text-lg md:text-xl text-center px-6">
                            <p className="font-bold text-blue-400 text-3xl mb-2">Sea Freight</p>
                            <p className="text-blue-500 text-2xl mb-4">Navigating the Waves with Logistica</p>
                            <p className="text-white hidden md:block">Delivering Excellence Across the Seas</p>
                            <p className="text-white hidden md:block">Logistica excels in sea freight, specializing in FCL cargo handling for all container types and cargoes, including special equipment. Our services also encompass LCL consolidation, tailored to accommodate clients with smaller packages.</p>
                        </div>
                    </div>
                    <img src={Sea} alt="Sea Freight service" className="object-cover w-full h-full transition-opacity duration-300 ease-in-out" />
                </div>

                {/* Second Grid Item */}
                <div className="relative group hover:scale-110 transition-transform duration-300 ease-in-out overflow-hidden h-96 md:h-auto">
                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                        <div className="text-white text-lg md:text-xl text-center px-6">
                            <p className="font-bold text-blue-400 text-3xl mb-2">Air Freight</p>
                            <p className="text-blue-500 text-2xl mb-4">Efficiency for Your Goods</p>
                            <p className="text-white hidden md:block">Simplified Air Freight Solutions</p>
                            <p className="text-white hidden md:block">Logistica offers top-tier air freight services, including air charter and expedited ground freight services nationwide. We specialize in handling air cargo swiftly and efficiently, catering to clients who require prompt and reliable transportation by air.</p>
                        </div>
                    </div>
                    <img src={Air} alt="Air Freight service" className="object-cover w-full h-full transition-opacity duration-300 ease-in-out" />
                </div>

                {/* Third Grid Item */}
                <div className="relative group hover:scale-110 transition-transform duration-300 ease-in-out overflow-hidden h-96 md:h-auto">
                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                        <div className="text-white text-lg md:text-xl text-center px-6">
                            <p className="font-bold text-blue-400 text-3xl mb-2">Road Freight</p>
                            <p className="text-blue-500 text-2xl mb-4">On the Move with Logistica</p>
                            <p className="text-white hidden md:block">VERSATILE ROAD AND RAIL TRANSPORT</p>
                            <p className="text-white hidden md:block">Logistica provides a comprehensive trailer-on-flat-car service, offering climate-controlled trailers for perishable goods requiring protection from extreme temperatures. Our services extend to handling non-perishable and general freight via both rail and road transport modes, ensuring reliable transportation solutions tailored to your needs.</p>
                        </div>
                    </div>
                    <img src={Road} alt="Road Freight service" className="object-cover w-full h-full transition-opacity duration-300 ease-in-out" />
                </div>

                {/* Fourth Grid Item */}
                <div className="relative group hover:scale-110 transition-transform duration-300 ease-in-out overflow-hidden h-96 md:h-auto">
                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                        <div className="text-white text-lg md:text-xl text-center px-6">
                            <p className="font-bold text-blue-400 text-3xl mb-2">Warehousing and Cargo</p>
                            <p className="text-blue-500 text-2xl mb-4">YOUR TRUSTED LOGISTICS PARTNER</p>
                            <p className="text-white hidden md:block">Logistica leads as a global provider of comprehensive supply chain solutions. Our state-of-the-art facilities integrate advanced systems and equipment to optimize warehousing and distribution costs while enhancing service levels. We offer value-added services including packaging, bundling, labeling, inspection, and quality control.</p>
                        </div>
                    </div>
                    <img src={hello} alt="Warehousing and Cargo service" className="object-cover w-full h-full transition-opacity duration-300 ease-in-out" />
                </div>
            </div>
        </>
    );
}

export default GridSection;
