import React, { useState, useEffect, useRef } from 'react';
import warehouseImage from '../assets/ware122.jpg'; // Adjust the path as necessary
import warehouseImage2 from '../assets/ware121.jpg'; // Adjust the path as necessary
import warehouseVideo from '../assets/warehouse111.mp4'; // Adjust the path as necessary
import Navbar from './Navbar';
import Footer from './Footer';

const Warehouse = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const imageRefs = useRef([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slideInRight', 'opacity-100');
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRefs = imageRefs.current;
    currentImageRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentImageRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <div className="h-screen relative">
          <Navbar />
          <div className="absolute inset-0 w-full h-full bg-black opacity-65">
            <img
              src={warehouseImage}
              alt="Warehouse interior"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-5xl font-bold mb-4 text-black">WAREHOUSE</h1>
            <p className="text-3xl font-semibold text-black">Secure and Efficient Storage Solutions</p>
          </div>
        </div>
      ) : (
        <div className="h-screen relative">
          <Navbar />
          <div
            className="absolute inset-0 w-full h-full bg-black opacity-65"
            style={{
              maxHeight: '100vh',
              overflow: 'hidden',
            }}
          >
            <video
              autoPlay
              loop
              muted
              className="absolute inset-0 w-full h-full object-cover"
              style={{
                maxHeight: '100vh',
                width: '100vw',
              }}
            >
              <source src={warehouseVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-8xl font-bold mb-4 text-black">WAREHOUSE</h1>
            <p className="text-5xl font-semibold text-black">Secure and Efficient Storage Solutions</p>
          </div>
        </div>
      )}
      <div className="font-sans">
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h2 className="text-3xl font-semibold text-custom-blue">Optimize Your Logistics with Our Warehouse Solutions</h2>
            <p className="mt-4 text-lg leading-relaxed">
              Are you in need of secure and efficient storage solutions for your goods? Our warehousing services offer a reliable and secure solution, guaranteeing the safe storage and timely distribution of your shipments. Our team of seasoned experts is dedicated to guiding you through the intricacies of warehouse management, providing personalized logistics solutions and competitive pricing tailored to your unique requirements.
            </p>
            <h3 className="text-2xl font-semibold text-custom-blue mt-6">Enhance Your Logistics Experience with Our Expertise</h3>
            <ul className="list-disc mt-4 text-lg leading-relaxed">
              <li><b>Secure Storage:</b> Our state-of-the-art facilities ensure your goods are stored safely and securely.</li>
              <li><b>Inventory Management:</b> Efficient inventory management systems to keep track of your goods with <b>Non-Bonded warehouses</b>.</li>
              <li><b>Flexible Solutions:</b> Customized warehousing solutions to meet your specific storage needs.</li>
              <li><b>Timely Distribution:</b> Fast and reliable distribution services to get your goods where they need to be.</li>
              <li><b>Surveillance:</b>We provide 24/7 coverage of <b>CCTV</b>.</li>
            </ul>
          </div>
          <div className="lg:w-1/2 flex justify-center">
            <img
              ref={(el) => (imageRefs.current[0] = el)}
              src={warehouseImage}
              alt="Interior of a modern warehouse"
              className="rounded-lg shadow-lg opacity-0"
              style={{ objectFit: 'cover', height: '400px', width: '100%', maxWidth: '550px' }}
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h3 className="text-2xl font-semibold text-custom-blue">Why Choose Our Warehouse Services?</h3>
            <p className="mt-4 text-lg leading-relaxed">
              At <b>LOGISTICA</b>, we understand the importance of efficient and secure storage in today's fast-paced market. Our warehousing services are designed to provide a reliable, cost-effective, and personalized solution for your storage needs. Contact us today to learn more about our warehouse services and how we can help optimize your logistics operations.
            </p>
          </div>
          <div className="lg:w-1/2 flex justify-center">
            <img
              ref={(el) => (imageRefs.current[1] = el)}
              src={warehouseImage2}
              alt="Warehouse facility"
              className="rounded-lg shadow-lg opacity-0"
              style={{ objectFit: 'cover', height: '400px', width: '100%', maxWidth: '550px' }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Warehouse;
