import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FooterBg } from '../assets/index.js';
import ContactUs from './ContactUs';
import { AnimatePresence } from 'framer-motion';

const Footer = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);

  const handleContactClick = () => {
    setIsContactOpen(true);
  };

  const handleCloseContact = () => {
    setIsContactOpen(false);
  };

  return (
    <footer id="footer" className="bg-custom-blue text-white">
      <div
        className="mx-auto text-center px-4 py-12"
        style={{
          backgroundImage: `url(${FooterBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h2 className="text-lg mb-2">Reliable Logistic Solutions</h2>
        <h1 className="text-2xl font-bold mb-4">
          CONNECT WITH US & EXPAND YOUR BUSINESS REACH.
        </h1>
        <button
          onClick={handleContactClick}
          className="bg-orange-500 text-white py-2 px-6 rounded-lg text-lg mb-12 hover:bg-orange-600 transition duration-300"
        >
          CONTACT US
        </button>
        <div className="flex flex-wrap justify-around mt-8">
          <div className="max-w-sm mb-8 text-center bg-white bg-opacity-20 p-4 rounded-lg backdrop-blur-lg">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" className="mb-4" />
            <h3 className="text-xl font-bold mb-2">Karachi Head Office</h3>
            <p>
              Office # 616, 6th Floor, The Forum Mall Khayaban-e-Jami, Block-9 Clifton Karachi Pakistan
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
              +92-213-5308885 – 86
            </p>
            <a
              href="https://maps.app.goo.gl/BeeCcnddeAGVinti7"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 text-white py-2 px-4 rounded-lg mt-4 inline-block hover:bg-blue-800 transition duration-300"
            >
              Get Direction
            </a>
          </div>
          <div className="max-w-sm mb-8 text-center bg-white bg-opacity-20 p-4 rounded-lg backdrop-blur-lg">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" className="mb-4" />
            <h3 className="text-xl font-bold mb-2">Lahore Branch Office</h3>
            <p>
              OFFICE # 410/411, 4TH FLOOR GUL MOHAR TRADE CENTER MAIN MARKET GULBERG LAHORE
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
              +92- 42 35197409
            </p>
            <a
              href="https://maps.app.goo.gl/PPAz8sdfXEBeQQkH9"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 text-white py-2 px-4 rounded-lg mt-4 inline-block hover:bg-blue-800 transition duration-300"
            >
              Get Direction
            </a>
          </div>
          <div className="max-w-sm mb-8 text-center bg-white bg-opacity-20 p-4 rounded-lg backdrop-blur-lg">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" className="mb-4" />
            <h3 className="text-xl font-bold mb-2">Sialkot Branch Office</h3>
            <p>
              OFFICE # 115, GROUND FLOOR, AL KHALIL CENTER, KASHMIR ROAD, SIALKOT - PAKISTAN.
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
              +92 523 552206
            </p>
            <a
              href="https://maps.app.goo.gl/mrFnq1kYi7S54EM1A"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 text-white py-2 px-4 rounded-lg mt-4 inline-block hover:bg-blue-800 transition duration-300"
            >
              Get Direction
            </a>
          </div>
        </div>
      </div>
      {/* Additional content at the end of the footer */}
<div className="bg-custom-blue text-white text-center py-2">
  <div className="container mx-auto px-4">
    <div className="flex flex-col sm:flex-row justify-between items-center">
      <p className="text-sm mb-2 sm:mb-0">
        &copy; Copyright Logistica Shipping & Logistics 2024 - All Rights Reserved
      </p>
      <p className="text-sm">
        Site by{' '}
        <a
          href="https://uraantech.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          Uraan Tech
        </a>
      </p>
    </div>
  </div>
</div>

      <AnimatePresence>
        {isContactOpen && <ContactUs onClose={handleCloseContact} />}
      </AnimatePresence>
    </footer>
  );
};

export default Footer;
