import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Air, Sea, Sea2 } from '../assets/index.js';
import GridSection from './Services.jsx';
import Navbar from './Navbar.jsx';
import Footer from './Footer.jsx';
import Affiliation from './Affiliation.jsx';

const slides = [
    {
        image: Air,
        title: 'LOGISTICA',
        subheading: 'Shipping and Logistics',
        description: 'Enhancing global trade with efficient air freight solutions. We ensure timely delivery and customer satisfaction.'
    },
    {
        image: Sea,
        title: 'LOGISTICA',
        subheading: 'Shipping and Logistics',
        description: 'Navigating the seas with reliable sea freight services. Our commitment to sustainability drives our operations.'
    },
    {
        image: Sea2,
        title: 'LOGISTICA',
        subheading: 'Shipping and Logistics',
        description: 'Streamlining logistics with advanced sea transport solutions. Providing cost-effective options with guaranteed customer satisfaction.'
    }
];

const Home = () => {
    return (
        <div>
            <Navbar />
            <div className="relative flex items-center justify-center h-screen w-full overflow-hidden">
                <Swiper
                    modules={[Autoplay, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    className="w-full h-full"
                    style={{
                        "--swiper-pagination-color": "#007bff",
                        "--swiper-pagination-bullet-inactive-color": "#999999",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "15px",
                        "--swiper-pagination-bullet-horizontal-gap": "10px"
                    }}
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index} className="relative">
                            <img src={slide.image} alt={`Slide ${index + 1}`} className="object-cover w-full h-full" />
                            <div className="absolute inset-0 bg-black opacity-50"></div>
                            <div className="absolute inset-0 flex flex-col justify-center items-center p-4 swiper-slide-text">
                                <h1 className="text-4xl md:text-6xl font-bold text-blue-600 relative cursor-default" style={{ color: '#006Eb4' }}>
                                    {slide.title.split('').map((char, idx) => (
                                        <span key={idx} className="relative z-10 hover:text-white">{char}</span>
                                    ))}
                                    <span className="absolute -bottom-1 -right-1 bg-white rounded-lg shadow-md"></span>
                                </h1>
                                <h2 className="text-2xl md:text-3xl font-medium text-white mt-2 cursor-default">
                                    {slide.subheading}
                                </h2>
                                <div className="max-w-lg mx-auto mt-8 text-lg md:text-xl font-medium text-white text-center cursor-default">
                                    {slide.description}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <GridSection />
            <Affiliation />
            <Footer />
        </div>
    );
}

export default Home;
