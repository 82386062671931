import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Seavid from '../assets/seavid.mp4';
import seafreightImage from '../assets/seafrieght121.jpg'; // Adjust the path as necessary
import sea23 from '../assets/seafrieght122.jpg'; // Adjust the path as necessary
const SeaFreight = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const imageRefs = useRef([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slideInRight', 'opacity-100');
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRefs = imageRefs.current;
    currentImageRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentImageRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        <div className="h-screen relative">
          <Navbar />
          <div className="absolute inset-0 w-full h-full bg-black opacity-65">
            <img src={seafreightImage} alt="Sea freight transport" className="absolute inset-0 w-full h-full object-cover" />
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-5xl font-bold mb-4 text-black">SEA FREIGHT</h1>
            <p className="text-3xl font-semibold text-black">Cost-Efficient Sea Freight Forwarding</p>
          </div>
        </div>
      ) : (
        <div className="h-screen relative">
          <Navbar />
          <div
            className="absolute inset-0 w-full h-full bg-black opacity-75"
            style={{
              maxHeight: '100vh',
              overflow: 'hidden',
            }}
          >
            <video
              autoPlay
              loop
              muted
              className="absolute inset-0 w-full h-full object-cover"
              style={{
                maxHeight: '100vh',
                width: '100vw',
              }}
            >
              <source src={Seavid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-8xl font-bold mb-4 text-black">SEA FREIGHT</h1>
            <p className="text-5xl font-semibold text-black">Cost-Efficient Sea Freight Forwarding</p>
          </div>
        </div>
      )}
      <div className="font-sans">
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h2 className="text-3xl font-semibold text-custom-blue">Cost-Efficient Sea Freight Forwarding From Trusted Shipping Experts</h2>
            <p className="mt-4 text-lg leading-relaxed">
              Looking for a cost-effective way to move your goods internationally? Sea freight is the world's most efficient method,
              but don't just settle for what you can find. Our experts will help you to strategically manage your sea freight and take
              advantage of the most efficient, reliable, competitively priced solutions for your needs. Our committed customer support team,
              global network, and shipping partners ensure timely booking and shipment of your consignment seamlessly. No inquiries,
              follow-ups, or unexpected delays.
            </p>
            <h3 className="text-2xl font-semibold text-custom-blue mt-6">Benefits of Sea Freight</h3>
            <ul className="list-disc mt-4 text-lg leading-relaxed">
              <li><b>Cost-Effective:</b> Sea freight is the most economical way to transport large volumes of goods over long distances.</li>
              <li><b>Reliable:</b> Our experienced team ensures that your shipment is handled with care and arrives at its destination on time.</li>
              <li><b>Flexible:</b> We offer a range of sea freight services to accommodate your specific needs, from LCL<b>(outbound consolidation and inbound deconsolidation)</b> to FCL<b>(contracted rates from all major shipping lines)</b> and more.</li>
              <li><b>Ex-Work:</b> Our global network and shipping partners allow us to provide In-house custom brokerage and In-house transportation.</li>
            </ul>
          </div>
          <div className="lg:w-1/2">
            <img
              ref={(el) => (imageRefs.current[0] = el)}
              src={seafreightImage}
              alt="Freight ship on the sea"
              className="rounded-lg shadow-lg opacity-0"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row p-6 lg:p-12 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-1/2">
            <h3 className="text-2xl font-semibold text-custom-blue">Why Choose Our Sea Freight Services?</h3>
            <p className="mt-4 text-lg leading-relaxed">
              At <b>LOGISTICA</b>, we understand the importance of timely and efficient sea freight services. Our team of experts is dedicated to providing
              personalized logistics solutions that meet your unique needs. With our global network and shipping partners, we ensure that your goods are
              handled with care and arrive at their destination on time.
            </p>
          </div>
          <div className="lg:w-1/2">
            <img
              ref={(el) => (imageRefs.current[1] = el)}
              src={sea23}
              alt="Cargo ship in port"
              className="rounded-lg shadow-lg opacity-0"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SeaFreight;
