import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";

const Contact = ({ onClose }) => {
    const formRef = useRef();
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs
            .send(
                'service_qghehyg',
                'template_8us3hhm',
                {
                    from_name: form.name,
                    from_email: form.email,
                    message: form.message,
                },
                '0XF9ApScOhnY7oRGp'
            )
            .then(() => {
                setLoading(false);
                
                setForm({
                    name: "",
                    email: "",
                    message: "",
                });
            }, (error) => {
                setLoading(false);
                
                console.log(error.text);
            });
    };

    return (
        <motion.div
            initial={{ x: '0%', y: '100%' }}
            animate={{ x: '0%', y: '0%' }}
            exit={{ x: '0%', y: '100%' }}
            transition={{ type: 'spring', stiffness: 100 }}
            className="fixed top-0 left-0 w-full h-full p-4 md:p-8 overflow-auto z-50 flex items-center justify-center"
        >
            <div className="w-full md:w-1/2 bg-white p-4 md:p-8 rounded-xl shadow-lg">
                <button onClick={onClose} className="bg-blue-800 hover:bg-[#1daef1] py-1 px-4 md:px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary mb-4 md:mb-8">
                    Close
                </button>
                <div className="mt-4 md:mt-12 flex flex-col gap-10 overflow-hidden">
                    <div className='bg-black-100 p-4 md:p-8 rounded-2xl'>
                        <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-4 md:gap-8">
                            <label className="flex flex-col">
                                <span className="mb-2 md:mb-4">Your Name</span>
                                <input
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    placeholder="What's your name?"
                                    className="bg-gray-100 py-2 px-4 md:py-4 md:px-6 placeholder-gray-500 text-black rounded-lg outline-none border-none font-medium"
                                    required
                                />
                            </label>
                            <label className="flex flex-col">
                                <span className="mb-2 md:mb-4">Your Email</span>
                                <input
                                    type="email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                    placeholder="What's your email?"
                                    className="bg-gray-100 py-2 px-4 md:py-4 md:px-6 placeholder-gray-500 text-black rounded-lg outline-none border-none font-medium"
                                    required
                                />
                            </label>
                            <label className="flex flex-col"> 
                                <span className="mb-2 md:mb-4">Your Message</span>
                                <textarea
                                    rows="4"
                                    name="message"
                                    value={form.message}
                                    onChange={handleChange}
                                    placeholder="What do you want to say?"
                                    className="bg-gray-100 py-2 px-4 md:py-4 md:px-6 placeholder-gray-500 text-black rounded-lg outline-none border-none font-medium"
                                    required
                                />
                            </label>
                            <button
                                type="submit"
                                className="bg-blue-800 hover:bg-[#3849dd] py-2 px-4 md:py-3 md:px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
                            >
                                {loading ? "Sending..." : "Send"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Contact;
